jQuery(document).ready(function($) {
	$('.testimonial-wrapper .flexslider .slides').slick({
		dots: true,
		slidesToScroll: 3,
		slidesToShow: 3,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToScroll: 2,
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 900,
				settings: {
					slidesToScroll: 1,
					slidesToShow: 1,
				}
			}
		]
	});

}); /* end of as page load scripts */